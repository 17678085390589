<template>
  <Header title="The Human Race - Around the Bay" />

  <!-- Container -->
  <Container>
    
    <div class="grid gap-10 md:grid-cols-10">
      <div class="md:col-span-10 my-auto">
        <h2 class="">
          The reaction to the premiere of The Human Race-Around the Bay has been
swift….and positive!

        </h2> 
      </div>
    </div>
    <div class="mx-auto mt-10 flow-root max-w-2xl  lg:mx-0 lg:max-w-none">
        <div class="-mt-8 sm:-mx-4 grid sm:grid-cols-4 sm:text-[0] lg:grid-cols-6">
          <div :class="testimonial.colspan"
           v-for="testimonial in testimonials" :key="testimonial.author.handle" class="pt-8 sm:inline-block sm:w-full sm:px-4">
            <figure class="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
              <blockquote class="text-gray-900">
                <div class="space-y-4" v-html="testimonial.body"></div>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <div>
                  <div class="font-semibold text-gray-900 text-base">{{ testimonial.author.name }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    <div class="grid gap-10 md:grid-cols-10 mt-20">
      <div class="md:col-span-5 lg:col-span-5 my-auto">
        <p>
          The Human Race - Around the Bay" is a captivating 60-minute documentary
hosted by Radio Hall of Famer and mental health advocate, Ted Michaels,
with incredible video footage shot by award-winning filmmaker Moe Masoudi. 
        </p>  
        <br/>
        <p>Through interviews, personal narratives, and immersive storytelling, it explores the ways in which running serves as a catalyst for mental wellbeing. Culminating in the iconic Around the Bay Road Race, the documentary celebrates resilience, community spirit, and the pursuit of mental wellness.</p>
      </div>
      <div class="md:col-span-5 lg:col-span-5">
        <img
          src="/drone.jpg"
          alt="Drone shot over building"
          class="w-full h-full object-cover rounded-xl"
        />
        <figcaption class="mt-4 flex items-center justify-center gap-x-2 text-sm leading-6 text-gray-500">
          <svg class="mt-0.5 h-5 w-5 flex-none text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd"></path>
</svg>
Drone footage from Moe Masoudi
        </figcaption>
      </div>
    </div>
    <div class="mt-32 text-center">
      <h2 class="mb-12">On May 6, 2024, a capacity crowd attended the premiere of the
documentary.</h2>
      <img
          src="/crowd.jpg"
          alt="crowd of people"
          class="w-full h-full object-cover rounded-xl"
        />
        <figcaption class="mt-4 flex items-center justify-center gap-x-2 text-sm leading-6 text-gray-500">
          <svg class="mt-0.5 h-5 w-5 flex-none text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd"></path>
</svg>
 Photo courtesy Les Palango
        </figcaption>
    </div>
    <div class="mt-32 grid gap-10 md:grid-cols-10">
      <div class="md:col-span-5 lg:col-span-5 order-1 md:order-none">
        <img
          src="/group.jpg"
          alt="3 women speaking on a microphone"
          class="w-full h-full object-cover rounded-xl"
        />
        
        <figcaption class="mt-4 flex items-center justify-center gap-x-2 text-sm leading-6 text-gray-500">
          <svg class="mt-0.5 h-5 w-5 flex-none text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd"></path>
</svg>
 Photo courtesy Les Palango
        </figcaption>
      </div>
      <div class="md:col-span-5 lg:col-span-5 my-auto">
        <p>After the documentary was aired, three of the people that bravely shared their personal stories in the movie, were involved in a Q and A, that left the crowd surprised… and inspired!</p>
        
      </div>
    </div>
    
    <div class="grid gap-10 md:grid-cols-10 mt-20">
      <div class="md:col-span-5 lg:col-span-5 my-auto">
        <p>
          Interested in showing the documentary? We welcome applications from organizations eager to share our documentary with their audience. Contact us to bring "The Human Race - Around the Bay" to your community and spark meaningful conversations around mental health and wellness.
        </p>  
        <br/>
        <p ><strong>For more information, contact:</strong> <a class="accent-link text-accent hover:no-underline underline" href="mailto:TedMichaels1954@outlook.com">TedMichaels1954@outlook.com</a></p>
      </div>
      <div class="md:col-span-5 lg:col-span-5">
        <img
          src="/ted.jpg"
          alt="Ted Michaels giving a presentation"
          class="w-full h-full object-cover rounded-xl"
        />
        <figcaption class="mt-4 flex items-center justify-center gap-x-2 text-sm leading-6 text-gray-500">
          <svg class="mt-0.5 h-5 w-5 flex-none text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd"></path>
</svg>
Photo courtesy Les Palango
        </figcaption>
      </div>
    </div>
  </Container>

  <div class="mt-20 bg-gray-100">
    <Container>
      <h2 class="text-center">Watch the documentary:</h2>
      <iframe class="mt-12 mx-auto" width="560" height="315" src="https://www.youtube.com/embed/1SYY8vFVZeQ?si=wkHI8KRDrtoaZ2BM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </Container>
    </div>
    
  <div class="">
    
    <Container>
      <h2 class="text-center">More Photos</h2>
      <div class="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="v in photos" :key="v.id" class="">
          <div class="rounded-lg object-cover relative overflow-hidden aspect-w-3 aspect-h-2 group cursor-pointer" v-on:click="
                                  open = true;
                                  photoId = v.id;
                                  desc = v.topic
                                ">
            <img :src="v.id" class="object-cover transform" :alt="v.alt"  :photoId="v.id" desc = v.topic/>
          </div>
        </div>
      </div>
    </Container>
  </div>


  <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>
  
          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start mt-8">
                <div class="text-center sm:mt-0 sm:text-left  w-full">
                  <div class="aspect-w-16 aspect-h-9 w-full">
                    <img :src="photoId" class="object-cover transform" />
                  </div>
                  <div class="">
                    {{desc}}
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  

  <!-- SimpleBrandCTA -->
  <SimpleBrandCTA />
</template>

<script>
import { ref } from 'vue'
import Header from "@/components/base/Header";
import Container from "@/components/layouts/Container.vue";
import SimpleBrandCTA from "@/components/layouts/cta/SimpleBrandCTA.vue";
import { ArrowCircleRightIcon } from "@heroicons/vue/outline";

import { Dialog, DialogOverlay,  TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { XIcon } from '@heroicons/vue/outline'
const testimonials = [
{
    body: "<p>“I've never attended a movie premiere so this was exciting for me.</p> <p>The doc highlighted several personal stories related to mental health and physical fitness that were inspiring, reinforcing the need for continued dialogue. The panel discussion brought all the feels.”</p>",
    colspan: "sm:col-span-2 lg:col-span-3",
    author: {
      name: 'Bhavisha, Ancaster.',
    },
  }, {
    body: "<p>“I was thrilled to learn about this event; it was truly inspiring! Since attending, I've been walking every day and can definitely feel the positive effects.</p> <p>Thank you for organizing such an incredible event.”</p>",
    colspan: "sm:col-span-2 lg:col-span-3",
    author: {
      name: 'April, Hamilton',
    },
  },{
    body: "<p>“Thank you so much for including me in your event. I thought the documentary was well done and wanted to let you know this hit home for me. One of my son’s best friends recently died by suicide. The system fails to meet the needs of kids as they get closer to 18. They basically have to start over at the beginning with their course of care. I had no idea and since seeing your documentary wondered often if my son’s friend could have become involved with a local group – walking or running or cycling could he have pulled through the red tape.</p> <p>Thank you again and I look forward to seeing what you do next.”</p>",
    colspan: "sm:col-span-4 lg:col-span-6",
    author: {
      name: 'Name withheld by request',
    },
  },
  // More testimonials...
]
const achievements = [
  "Selected as a celebrity torch bearer, when the 2015 Pan Am games torch came through Hamilton, on June 22.",
  "Initiated the <em>&ldquo;I'm In, Are You?&rdquo;​</em> marketing campaign for the 2011 Around the Bay Road Race, in support of St. Joseph's Healthcare Foundation. Helped in the promotion and awareness of the campaign, from the initial news conference, through an 8-week series, plus a daily blog.",
  "<strong>Surpassed the target goal of 200-thousand dollars with over 220-thousand dollars in donations and sponsorships obtained.</strong>",
  "In seven years, as captain of Team CHML, the team raised over 77--thousand dollars for St. Joseph's Healthcare Foundation.",
  "Invited then, Premier Kathleen Wynne, to run with Team CHML.",
  "Taught a starters 5K running class, building self-confidence and physical stamina.",
  "Initiated an interview with Rock and Roll Hall of Fame group, Chicago, when they appeared in Hamilton.  This was the only interview they did.  ",
];

const photos = [
    {
      topic: " Photo courtesy Les Palango",
      id: "_DSC0483.jpg",
      alt: "Ted taking a photo with atendee"
    },
    {
      topic: "T Photo courtesy Les Palango",
      id: "_DSC0485.jpg",
      alt: "Ted taking a photo with atendee"
    },
    {
      topic: " Photo courtesy Les Palango",
      id: "_DSC0488.jpg",
      alt: "Ted taking a photo with atendee"
    },
    
  ];
const open = ref(false)
      const photoId = ref("");
      const desc = ref("");
export default {
  components: {
    Header,
    Container,
    SimpleBrandCTA,
    ArrowCircleRightIcon,
    
    Dialog,
      DialogOverlay,
      TransitionChild,
      TransitionRoot,
      XIcon,
  },
  data() {
    return {
      achievements,
      testimonials,
      open,
        photoId,
        desc,
        
        photos,
    };
  },
};
</script>
